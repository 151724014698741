// $darkBlack: #282c35;
@import '../_vars.scss';
$darkBlack: #292929;
$nightWhite: hsla(0,0%,98%,1);
$borderWidth: 3px;

.site-content.dragon {
  transition: background-color 0.3s;
}
.cart.minimized {
  transition: background-color 0.3s;
}
.pages-nav {
  transition: background-color 0.3s;
  display: flex;
  flex-wrap: warp;
  align-items: center;
  ul {
    flex: 1;
  }
  .react-toggle {
    margin-right: 0.5em;
  }
}
.site-content.dragon.dark {
  color: $nightWhite;
  background-color: $darkBlack;
  // background-color: #222222;
  // background-color: #181818;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23d2a332' fill-opacity='0.08' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
  transition: background-color 0.3s;
}
.dragon.dark {
  a.address-link {
    color: $nightWhite;
  }
  a.checkout-button.button {
    background: $red;
    text-transform: capitalize;
    color: $nightWhite;
    font-weight: 600;
  }
  img.nav-bar-logo {

    box-sizing: border-box;
    background: url(https://afag.imgix.net/bierhaus/logo-dark-1.png?h=300) no-repeat;
    background-size: contain;
    width: 260px;
    display: block;
    // height: 40px;
    // margin-left: 5px;
    padding-left: 260px !important;
    // background: blue;
    // padding-bottom: 1em;
    // margin-bottom: 5px;
  }
  .cart.minimized {
    color: $nightWhite;
    background: $darkBlack;
    // border-bottom: 2px solid $nightWhite;
  }
  a.page-link {
    font-weight: 700;
    color: $nightWhite;
    border-bottom: $borderWidth solid $darkBlack;
    &:hover, &.active {
      color: $nightWhite;
      border-bottom: $borderWidth solid $red;
    }
  }
  .pages-nav {
    color: $nightWhite;
    background: $darkBlack;
  }
  .quick-links {
    background: $darkBlack;
    color: $nightWhite;
  }
  .menu-features {
    a.navItem {
      background: black;
    }
  }
}



.dragon.dark {
  .landing-page {
    // background-image: url(https://images.unsplash.com/photo-1542319920155-a236a55f1a97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=50);
    // background-position: 0% 50%;

  }


  a.page-link {
    color: $nightWhite;
    border-bottom-color: $darkBlack;
    &:hover, &.active {
      border-bottom-color: $nightWhite;
    }
  }

  .landing-page {
    .text {
      color: $nightWhite;
      background: rgba(0,0,0,0.7);
    }
  }


}
$darkCheckoutPaneColor: #185500;
.dark {
  .menu-story .category-name .text {
    color: $nightWhite;
    background: none;
  }
  .category__header {
    border-bottom: 1.5px solid white;
  }
  .product-wrapper {
    color: $nightWhite;
    background-color: #222;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23bdbdbd' fill-opacity='0.18' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    .product-info {
      color: $nightWhite;
    }
    .panel, .product-modgroup {
      background: #333;
      box-shadow: 0px 2px 4px #0f0f0f;
    }
  }

  .checkout-container {
    .step-number {
      background: $nightWhite;
      color: $darkBlack;
    }
  }
  .actions {
    .button {
      color: $darkBlack;
      font-weight: 500;
    }
  }
  .cart-type-container {
    background: $darkCheckoutPaneColor;
    color: $nightWhite;
  }

  .checkout__cash-payment {
    color: $nightWhite;
    background: $darkCheckoutPaneColor;
  }
  .price-summary {
    color: $nightWhite;
  }
  .switch-button-group, .cart-type-container {
    color: $nightWhite;
    label.selected {
      background: $darkCheckoutPaneColor;
    }
    label.not-selected {
      background: #8bb487;
    }
  }
  .place-order-section {
    background: $darkCheckoutPaneColor;
    .place-order-button {
      background: #16b7ff;
    }
  }
  .footer {
    background: #111;
  }
}
